<template>
  <section class="modern-house">
    <section class="hero">
      <div class="hero-container">
        <div class="hero-container-title">
          <h1>Faire construire sa maison moderne</h1>
        </div>
        <div class="hero-container-img">
          <img
            src="@/assets/img/pages/modern-house-1.jpg"
            alt="Faire construire sa maison moderne"
          />
        </div>
      </div>
    </section>
    <article class="article">
      <section class="fit-content card">
        <div class="smartpage-card smartpage-card-reverse">
          <div class="smartpage-card-content">
            <div>
              <h2>Le plan de la maison au service des besoins et des tendances</h2>
              <p>
                <strong>Qu’est-ce qu’une maison moderne ?</strong>
              </p>
              <p>De nos jours, la modernité n’est pas plus un concept d’actualité… De même, le moderne semble s’opposer au traditionnel, mais cela a-t-il du sens de l’opposer à un style ? Il faut dépasser ces 2 idées reçues pour exprimer ce qu’offre une vraie maison moderne :</p>
              <ul>
                <li>
                  <p>une conception et un plan étudiés pour s’adapter à un mode de vie combinant usages familiaux et sociaux, conférant à l’habitat des beaux volumes, une circulation optimisée et un confort remarquable</p>
                </li>
                <li>
                  <p>un mode constructif et des matériaux actuels destinant la construction à des performances en termes d’isolation, d’économies d’énergie et à une légitime pérennité</p>
                </li>
                <li>
                  <p>des possibilités stylistes et esthétiques nombreuses et personnalisées.</p>
                </li>
              </ul>
              <p>Qu’elle soit de 2 ou 5 chambres, de plain-pied ou à étage, avec un plan en forme de L ou avec un plan multiforme complexe, la maison moderne s’affiche résolument la vôtre, parce qu’adaptée à vos besoins, à votre style de vie et offrant des aménagements et un niveau d’équipements à la hauteur de vos exigences.</p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/modern-house-2.jpg"
              alt="Le plan de la maison au service des besoins et des tendances"
            />
          </div>
        </div>
      </section>
      <section>
        <div class="smartpage-costing">
          <div class="smartpage-costing-content">
            <div>
              <h2>Configurez et chiffrez votre projet en moins de 2 minutes !</h2>
              <router-link class="costing-btn" :to="{ name: 'home', hash: '#hero' }">Commencer mon projet</router-link>
            </div>
            <div>
              <div>
                <img src="@/assets/img/chrono.svg" alt="chrono icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="fit-content card">
        <div class="smartpage-card">
          <div class="smartpage-card-content">
            <div>
              <h2>Quand modernité rime avec actualité</h2>
              <p>Le maison moderne se définit également par son aspect général, tant par ses volumes que par son esthétique. Les lignes sont graphiques car les volumes sont géométriques, qu’ils soient de simples rectangles ou une agrégation de formes cubiques. Les ouvertures sont nombreuses, diverses et privilégient la baie vitrée, pour laisser entrer la lumière comme pour atteindre des objectifs d’ordre thermique.</p>
              <p>Tous les aménagements sont possibles : demi-niveaux, enfilades, patio intérieur… Laissez parler votre imagination et puisez vos inspirations dans les réalisations des constructeurs.</p>
              <p>A l’extérieur aussi, votre personnalité s’affirme : effet d’enduits, de couleurs et de matériauxIl est possible de jouer les tendances avec un toit plat ou mono-pente, ou d’une combinaison avec un toit à pente traditionnelle. Attention à la réglementation si vous êtes en lotissement ! Consultez l’expertise de votre constructeur Maisons Alysia qui saura vous conseiller et vous accompagner dans la définition de votre projet.</p>
              <p>Toutes les compositions sont possibles pour créer un style, votre style, du plus sage au plus original.</p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/modern-house-3.jpg"
              alt="Quand modernité rime avec actualité"
            />
          </div>
        </div>
      </section>
    </article>
  </section>
</template>

<script>
export default {
  name: 'modern-house',
};
</script>

<style lang="sass">
.modern-house
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    @include smartpage-hero
  .article
    margin-bottom: 5rem
    .card + .card
      margin-top: 20rem
    section
      margin-top: 8rem
      &:first-child
        margin-top: 35rem
      .smartpage-card
        @include smartpage-card
      .smartpage-card-reverse
        @include smartpage-card-reverse
        .smartpage-card-img
          margin-bottom: -25rem
      .smartpage-costing
        @include smartpage-costing
      .smartpage-card-img
        margin-top: -25rem
      ul
        padding-left: 0
        li
          position: relative
          padding-left: 1.8rem
          list-style-type: none
          margin-bottom: 1rem
          &:before
            position: absolute
            top: 0.3rem
            left: 0
            content: ' '
            width: 20px
            height: 20px
            background-image: url("../../assets/img/arrow-blue.svg")
  .fit-content
    width: 90%
    max-width: 1180px
    margin: 0 auto
  @media (max-width: 768px)
    .article
      margin-bottom: 5rem
      section
        margin-top: 1.5rem
        &:first-child
          margin-top: 10rem
        .smartpage-card-img
          margin-top: 1.5rem
        .smartpage-card-reverse
          .smartpage-card-img
            margin-bottom: 0
      .card + .card
        margin-top: 1.5rem
</style>
